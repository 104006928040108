import React from 'react'

function ArrowRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#54585F"
        fillRule="evenodd"
        d="M6.911 3.577a.833.833 0 011.179 0l5.833 5.834a.833.833 0 010 1.178L8.09 16.422a.833.833 0 01-1.179-1.178L12.155 10 6.911 4.756a.833.833 0 010-1.179z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default ArrowRight
