/* eslint-disable react/no-array-index-key */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useRef } from 'react'
import { useSnapCarousel } from 'react-snap-carousel'

import { ArrowLeft, ArrowRight, Star } from 'icons'
import useWindowDimensions from '../hooks/useWindowDimensions'
import CONFIG from '../config.json'

interface CarouselProps<T> {
  readonly items: T[];
  readonly renderItem: (
    props: CarouselRenderItemProps<T>
  ) => React.ReactElement<CarouselItemProps>;
}

interface CarouselRenderItemProps<T> {
  readonly item: T;
  readonly isSnapPoint: boolean;
}

export function Carousel<T extends any>({
  items,
  renderItem,
}: CarouselProps<T>) {
  const { width } = useWindowDimensions()

  const nextButtonRef = useRef(null)
  const {
    scrollRef,
    pages,
    activePageIndex,
    prev,
    next,
    goTo,
    snapPointIndexes,
  }: any = useSnapCarousel()

  useEffect(() => {
    if (scrollRef.current) {
      console.log(scrollRef)
      scrollRef.current.scrollRight = 100
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="reviews-root">
      <ul className="reviews-list" ref={scrollRef}>
        {items.map((item, i) =>
          renderItem({
            item,
            isSnapPoint: snapPointIndexes.has(i),
          }))}
      </ul>
      <div className="carousel-controls" aria-hidden>
        <button
          type="button"
          className={`carousel-button ${activePageIndex === 0 ? 'disabled' : ''}`}
          onClick={() => prev()}
        >
          <ArrowLeft />
        </button>
        <div className="carousel-points">
          {pages.map((_, i) => (
            <button
              type="button"
              key={i}
              onClick={() => goTo(i)}
            >
              <div className={`carousel-point ${activePageIndex === i ? 'selected' : ''}`} />
            </button>
          ))}
        </div>
        <button
          type="button"
          className={`carousel-button ${activePageIndex === pages.length - 1 ? 'disabled' : ''}`}
          onClick={() => next()}
          ref={nextButtonRef}
        >
          <ArrowRight />
        </button>
      </div>
    </div>
  )
}

interface CarouselItemProps {
  language: string,
  englishText: string,
  arabicText: string,
  isSnapPoint: any,
}

const styles = {
  itemSnapPoint: {
    scrollSnapAlign: 'start',
  },
}

export function CarouselItem({
  language, englishText, arabicText, isSnapPoint,
}: CarouselItemProps) {
  return (
    <div className="review" style={isSnapPoint ? { ...styles.itemSnapPoint } : {}}>
      <div className="review-top-block">
        <div className="stars">
          {new Array(5).fill(null).map((_, index) => <Star key={index} />)}
        </div>
        <span>
          {language === 'ar' ? (
            arabicText
          ) : (
            englishText
          )}
        </span>
      </div>
      <div className="reviewer-details">
        <div>
          <img alt="reviewer-avatar" src="./images/reviewerAvatar.png" />
        </div>
        <div>
          <p>Nadir Abdullah</p>
          <span>14/07/2023</span>
        </div>
      </div>
    </div>
  )
}
