import React from 'react'

interface WorldwideProps {
  color: string;
}

function Worldwide({ color }: WorldwideProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M2.05 11h4.156c.105-2.753.637-5.264 1.475-7.179.167-.38.348-.744.543-1.084A10.008 10.008 0 002.05 11zM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 2c-.334 0-.73.15-1.178.583-.453.438-.906 1.12-1.309 2.04C8.803 6.248 8.312 8.476 8.208 11h7.584c-.104-2.524-.594-4.752-1.305-6.377-.403-.92-.856-1.602-1.309-2.04C12.73 2.15 12.334 2 12 2zm5.794 9c-.105-2.753-.637-5.264-1.475-7.179a11.06 11.06 0 00-.543-1.084A10.008 10.008 0 0121.95 11h-4.157zm-2.002 2H8.208c.104 2.524.594 4.752 1.305 6.377.403.92.856 1.602 1.309 2.04.448.433.844.583 1.178.583.334 0 .73-.15 1.178-.583.453-.438.906-1.12 1.309-2.04.71-1.625 1.201-3.853 1.305-6.377zm-.016 8.263c.195-.34.377-.703.543-1.084.838-1.915 1.37-4.426 1.475-7.179h4.157a10.008 10.008 0 01-6.175 8.263zm-7.552 0c-.195-.34-.377-.703-.543-1.084-.838-1.915-1.37-4.426-1.475-7.179H2.05a10.009 10.009 0 006.175 8.263z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Worldwide
