import React from 'react'

interface TiktokProps {
  color: string
}

function Tiktok({ color = '#7D4AFB' }: TiktokProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      fill="none"
      viewBox="0 0 41 40"
    >
      <path
        fill={color}
        d="M25.573 8h-4.045v16.348c0 1.948-1.556 3.548-3.492 3.548s-3.491-1.6-3.491-3.548c0-1.913 1.52-3.479 3.388-3.548v-4.104c-4.114.07-7.433 3.443-7.433 7.652C10.5 28.59 13.888 32 18.07 32c4.184 0 7.572-3.444 7.572-7.652v-8.383A9.366 9.366 0 0031 17.774V13.67c-3.042-.105-5.427-2.61-5.427-5.67z"
      />
    </svg>
  )
}

export default Tiktok
