import React from 'react'

function Close() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M4.41 4.41a.833.833 0 011.18 0L10 8.823l4.41-4.411a.833.833 0 111.18 1.178L11.178 10l4.41 4.41a.833.833 0 11-1.178 1.18L10 11.178l-4.412 4.41a.833.833 0 11-1.178-1.178L8.82 10l-4.41-4.412a.833.833 0 010-1.178z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Close
