/* eslint-disable max-len */
import React from 'react'

function PrivacyPolicy(): ReturnType<React.FC> {
  return (
    <div className="container">
      <div className="terms-of-service">
        <h2>Privacy policy</h2>
        <span>
          At Karizma, we understand the importance of protecting your privacy and personal information.
          This Privacy Policy explains how we collect, use, and share information about you when you use our video-based job matching Service.
          We encourage you to read this policy carefully to understand our practices and your choices regarding your information.
        </span>

        <p>Information We Collect</p>
        <p className="sub-header">When you use the Karizma Service, we may collect the following types of information:</p>
        <div className="list">
          <div>
            <img width="8" src="./images/ellipse.png" alt="ellipse" />
            <span>Personal information, such as your name, email address, phone number, and resume.</span>
          </div>
          <div>
            <img width="8" src="./images/ellipse.png" alt="ellipse" />
            <span>Profile information, such as your job preferences, skills, work experience, education, and qualifications.</span>
          </div>
          <div>
            <img width="8" src="./images/ellipse.png" alt="ellipse" />
            <span>Video and audio recordings of yourself, which will be used for matching you with potential employers and employees.</span>
          </div>
          <div>
            <img width="8" src="./images/ellipse.png" alt="ellipse" />
            <span>Location information, which will be used to show you job opportunities in your area, as well as matching candidates.</span>
          </div>
          <div>
            <img width="8" src="./images/ellipse.png" alt="ellipse" />
            <span>Usage information, such as how you interact with the Karizma Service, including the pages or features you visit, your search queries, and the time and date of your use.</span>
          </div>
          <div>
            <img width="8" src="./images/ellipse.png" alt="ellipse" />
            <span>Device information, such as your device type, operating system, browser type, and IP address.</span>
          </div>
        </div>

        <p>How We Use Your Information</p>
        <p className="sub-header">We use the information we collect to:</p>
        <div className="list">
          <div>
            <img width="8" src="./images/ellipse.png" alt="ellipse" />
            <span>Provide and improve the Karizma Service and its features.</span>
          </div>
          <div>
            <img width="8" src="./images/ellipse.png" alt="ellipse" />
            <span>Match you with potential employers based on your skills, experience, and qualifications.</span>
          </div>
          <div>
            <img width="8" src="./images/ellipse.png" alt="ellipse" />
            <span>Send you notifications and updates about job opportunities that match your preferences.</span>
          </div>
          <div>
            <img width="8" src="./images/ellipse.png" alt="ellipse" />
            <span>Analyze and improve our services.</span>
          </div>
          <div>
            <img width="8" src="./images/ellipse.png" alt="ellipse" />
            <span>Communicate with you about your use of the Karizma Service, including providing customer service and addressing any issues you may have.</span>
          </div>
          <div>
            <img width="8" src="./images/ellipse.png" alt="ellipse" />
            <span>Conduct research and analysis, including to improve and develop new features and services.</span>
          </div>
          <div>
            <img width="8" src="./images/ellipse.png" alt="ellipse" />
            <span>Comply with legal and regulatory requirements.</span>
          </div>
        </div>

        <p>Sharing Your Information</p>
        <p className="sub-header">We will not share your personal information with any third party without your consent, except in the following circumstances:</p>
        <div className="list">
          <div>
            <img width="8" src="./images/ellipse.png" alt="ellipse" />
            <span>We may share your information with potential employers to assist in the job matching process.</span>
          </div>
          <div>
            <img width="8" src="./images/ellipse.png" alt="ellipse" />
            <span>We may share your information with third-party service providers to assist in the operation and improvement of the Karizma Service.</span>
          </div>
          <div>
            <img width="8" src="./images/ellipse.png" alt="ellipse" />
            <span>We may disclose your information if we are required to do so by law or if we believe that such action is necessary to protect the rights, property, or safety of Karizma, our users, or others.</span>
          </div>
          <div>
            <img width="8" src="./images/ellipse.png" alt="ellipse" />
            <span>We may share non-personally identifiable information, such as aggregated data, with third parties for research and analysis or for other business purposes.</span>
          </div>
        </div>

        <p>Your Choices</p>
        <p className="sub-header">You can control how we use your information by adjusting your settings in the Karizma Service. You can also request that we delete your personal information by contacting us.</p>
        <p className="sub-header">You can also exercise the following rights with respect to your information:</p>
        <div className="list">
          <div>
            <img width="8" src="./images/ellipse.png" alt="ellipse" />
            <span>Access your information: You have the right to request access to your personal information and to obtain a copy of it.</span>
          </div>
          <div>
            <img width="8" src="./images/ellipse.png" alt="ellipse" />
            <span>Update your information: You have the right to request that we correct any inaccuracies in your personal information.</span>
          </div>
          <div>
            <img width="8" src="./images/ellipse.png" alt="ellipse" />
            <span>Delete your information: You have the right to request that we delete your personal information, subject to certain exceptions provided by law.</span>
          </div>
          <div>
            <img width="8" src="./images/ellipse.png" alt="ellipse" />
            <span>Object to our processing: You have the right to object to our processing of your personal information for certain purposes, such as direct marketing.</span>
          </div>
        </div>

        <p>Changes to Our Privacy Policy</p>
        <span>
          We may update this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of this policy and, in some cases, providing you with additional notice (such as adding a statement to our homepage or sending you an email notification).
        </span>

        <p>Contact Us</p>
        <span>
          If you have any questions or concerns about our Privacy Policy or the information we collect, please contact us at
          <span className="underline"> support@karizma.co</span>
        </span>
      </div>
    </div>
  )
}

export default PrivacyPolicy
