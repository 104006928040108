import React from 'react'

import CONFIG from '../../config.json'
import useWindowDimensions from '../../hooks/useWindowDimensions'

interface TrustedByBlockProps {
  language: string;
}

function TrustedByBlock({ language }: TrustedByBlockProps) {
  const { width } = useWindowDimensions()

  return (
    <div className="trusted-by-block">
      <p>{language === 'en' ? 'Trusted by' : 'موثوق به'}</p>
      {Number(width) <= Number(CONFIG.mobileWidth.split('px')[0]) ? (
        <div className="mobile-company-logos">
          <div>
            <img width="132" alt="marroff" src="./images/trusted1.png" />
            <img width="67" alt="starbucks" src="./images/trusted2.png" />
          </div>
          <div>
            <img width="215" alt="hyatt" src="./images/trusted3.png" />
            <img width="103" alt="hilton" src="./images/trusted4.png" />
          </div>
          <div>
            <img width="68" alt="g" src="./images/trusted5.png" />
            <img width="112" alt="1/2 M" src="./images/trusted6.png" />
          </div>
        </div>
      ) : (
        <div className="company-logos">
          <img width="132" alt="marroff" src="./images/trusted1.png" />
          <img width="67" alt="starbucks" src="./images/trusted2.png" />
          <img width="215" alt="hyatt" src="./images/trusted3.png" />
          <img width="103" alt="hilton" src="./images/trusted4.png" />
          <img width="68" alt="g" src="./images/trusted5.png" />
          <img width="112" alt="1/2 M" src="./images/trusted6.png" />
        </div>
      )}
    </div>
  )
}

export default TrustedByBlock
