import React from 'react'

interface ToogleProps {
  active: string,
  onToggle: (newOption: any) => void,
  type: 'languages' | 'userTypes',
  language: string,
  changeLanguage?: (newLanguage: string) => void,
}

function Toggle({
  active, onToggle, type, language, changeLanguage,
}: ToogleProps) {
  return (
    <div className="toggle-wrapper">
      {type === 'userTypes' ? (
        <>
          <button
            type="button"
            className={active === 'employers' ? 'active' : ''}
            onClick={() => onToggle('employers')}
          >
            {language === 'en' ? 'Employers' : 'الشركات'}
          </button>
          <button
            type="button"
            className={active === 'candidates' ? 'active' : ''}
            onClick={() => onToggle('candidates')}
          >
            {language === 'en' ? 'Candidates' : 'الباحثين عن العمل'}
          </button>
          <div className={`${language} ${type} animated-block ${active === 'candidates' ? 'active' : ''}`} />
        </>
      ) : (
        <>
          <button
            type="button"
            className={active === 'en' ? 'active' : ''}
            onClick={() => {
              changeLanguage('en')
              onToggle('en')
            }}
          >
            USA
          </button>
          <button
            type="button"
            className={active === 'ar' ? 'active' : ''}
            onClick={() => onToggle('ar')}
          >
            Saudi Arabia
          </button>
          <div className={`${type} animated-block ${active === 'ar' ? 'active' : ''}`} />
        </>
      )}
    </div>
  )
}

export default Toggle
