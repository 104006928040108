/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable max-len */
import React from 'react'

function TermsOfService(): ReturnType<React.FC> {
  return (
    <div className="container">
      <div className="terms-of-service">
        <h2>Terms of Service</h2>
        <span>
          Welcome to Karizma, a video-based job matching app. By using our app, you agree to be bound by the following terms of service:
        </span>

        <p>Eligibility:</p>
        <span>You must be at least 18 years of age to use our app.</span>
        <br />
        <span>If you are under 18, you may use our app only with the involvement of a parent or guardian.</span>

        <p>Account and Password:</p>
        <span>You are responsible for maintaining the confidentiality of your account and password and for restricting access to your device.</span>
        <br />
        <span>You agree to accept responsibility for all activities that occur under your account or password.</span>

        <p>Profile and Job Matching:</p>
        <span>You must provide accurate and complete information when creating your profile and during the job matching process.</span>
        <br />
        <span>Providing false or misleading information may result in termination of your account.</span>

        <p>Content:</p>
        <span>You must not upload any content that is illegal, offensive, or infringes on the rights of others.</span>
        <br />
        <span>You are solely responsible for the content you upload to the app.</span>

        <p>License:</p>
        <span>By uploading content to the app, you grant Karizma a non-exclusive, royalty-free, transferable, sub-licensable, worldwide license to use, display, reproduce, and distribute that content for the purpose of providing job matching services.</span>

        <p>Modification and Termination:</p>
        <span>We reserve the right to modify or terminate the app for any reason, without notice at any time.</span>

        <p>Refusal of Service:</p>
        <span>We reserve the right to refuse service to anyone for any reason at any time.</span>

        <p>User-Generated Content:</p>
        <span>Karizma is not responsible for the accuracy or reliability of any user-generated content, and will not be liable for any errors or omissions in any content or for any loss or damage of any kind incurred as a result of the use of any content posted, emailed, transmitted or otherwise made available via the app.</span>

        <p>Waiver and Severability:</p>
        <span>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.</span>
        <br />
        <span>If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect.</span>

        <p>Entire Agreement:</p>
        <span>These Terms constitute the entire agreement between us regarding our app, and supersede and replace any prior agreements we might have between us regarding the app.</span>

        <p>Updates:</p>
        <span>We may update these terms of service from time to time, so please check back frequently.</span>
        <br />
        <span>Your continued use of the app after any changes to these terms of service will constitute your acceptance of such changes.</span>

        <p>Indemnification:</p>
        <span>You agree to compensate, defend and hold harmless Karizma and its officers, directors, employees and agents, from and against any claims, actions or demands, including without limitation reasonable legal and accounting fees, alleging or resulting from your use of the app.</span>

        <p>Disclaimer of Warranties:</p>
        <span>
          {'We provide the app on an "as is" and "as available" basis.'}
        </span>
        <br />
        <span>We make no representations or warranties of any kind, express or implied, as to the operation of the app or the information, content, materials, or products included on the app.</span>

        <p>Limitation of Liability:</p>
        <span>To the fullest extent permitted by applicable law, in no event shall Karizma or its officers, directors, employees, or agents be liable for any indirect, punitive, incidental, special, or consequential damages arising out of or in any way connected with the use of the app, whether based on contract, tort, strict liability, or otherwise, even if advised of the possibility of such damages.</span>

        <p>Governing Law:</p>
        <span>These terms of service shall be governed by and construed in accordance with the laws of the state of Delaware, without giving effect to any principles of conflicts of law.</span>

        <p>Dispute Resolution:</p>
        <span>Any dispute arising out of or related to these terms of service or the use of the app shall be resolved through binding arbitration in accordance with the American Arbitration Association&apos;s rules for arbitration of consumer-related disputes, and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof.</span>

        <p>Contact Us:</p>
        <span>
          If you have any questions or concerns about these terms of service, please contact us at
          <span className="underline"> support@karizma.co</span>
          .
        </span>

        <p>Acceptance of Terms:</p>
        <span>By using the app, you acknowledge that you have read and understood these terms of service and that you agree to be bound by them.</span>
        <br />
        <span>If you do not agree to these terms of service, you may not use the app.</span>
        <br />
        <span>Thank you for using Karizma. We hope you enjoy using our app to find your dream job.</span>
      </div>
    </div>
  )
}

export default TermsOfService
