/* eslint-disable react/no-array-index-key */
import React from 'react'

import { UserType } from 'App'
import useWindowDimensions from '../hooks/useWindowDimensions'
import StoreButtons from './StoreButtons'
import ContactUsBlock from './blocks/ContactUsBlock'
import UniversalBlock from './blocks/UniversalBlock'
import TrustedByBlock from './blocks/TrustedByBlock'
import ReviewsList from './ReviewsList'
import ReviewsListMobile from './ReviewsListMobile'
import CONFIG from '../config.json'

function USABodyContent({ userType }: any) {
  const { width } = useWindowDimensions()

  return (
    <>
      <div className="hire-block">
        <div className="left-block">
          {userType === UserType.candidates ? (
            <p>
              The
              <span className="whisper-text"> fastest </span>
              way
              <br />
              to find your
              <br />
              dream job
            </p>
          ) : (
            <p>
              Your
              <span className="purple-text"> ai-powered </span>
              <br />
              recruiter
              <br />
              <span className="sub-title">The easiest way to hire the best candidates</span>
            </p>
          )}
          <StoreButtons userType={userType} />
          <div className="qr-code">
            <img alt="qr-code" src={`./images/qrCode${userType === UserType.candidates ? 'Candidate' : 'Employer'}.png`} />
          </div>
        </div>
        <div className="right-block">
          <img
            className={userType === UserType.candidates ? 'inactive' : 'active'}
            alt="iphone-avatar"
            src="./images/hireBlockImage.webp"
          />
          <img
            className={userType === UserType.candidates ? 'active' : 'inactive'}
            alt="iphone-avatar"
            src="./images/hireBlockImage.webp"
          />
        </div>
      </div>

      {userType === UserType.candidates ? (
        <>
          <div className="join-thousands-block">
            <p>
              Join thousands of candidates globally
              <br />
              who are finding their
              <span className="whisper-text"> dream </span>
              jobs
            </p>
            <div className="dream-members">
              {width <= Number(CONFIG.mobileWidth.split('px')[0]) ? (
                <>
                  <div>
                    <img alt="Person at Work" src="./images/dreamMember3.png" />
                  </div>
                  <div>
                    <img alt="Person at Work" src="./images/dreamMember4.png" />
                  </div>
                </>
              ) : (
                new Array(6).fill(null).map((_, index) => (
                  <div key={index}>
                    <img alt="Person at Work" src={`./images/dreamMember${index + 1}.png`} />
                  </div>
                ))
              )}
            </div>
          </div>

          <div className="looking-job-block">
            <p>
              Looking for a job? Make a lasting impression with a video resume! Our
              job search app lets you record a brief video introducing yourself to potential
              employers. Take the first step towards your dream career with a video resume!
            </p>
          </div>

          <UniversalBlock
            headerText="What you see is what you get"
            descriptionText="Gone are the days were you read endless job descriptions. With Karizma, you will get to see where you will be working, the people that you will be working with, and get a feel for the overall work environmen"
            type="left-image"
            imageSrc="./images/whatYouSeeBlockImage.png"
            withStoreButtons
          />

          <UniversalBlock
            headerText="Ghosting by employers is a thing of the past"
            descriptionText="There's nothing worth than being left without knowing the status of your application. That's why we made it our mission at Karizma to eliminate ghosting by Employers. You will get to see the real-time status of your application as it moves through the process."
            type="right-image"
            imageSrc="./images/ghostingBlockImage.png"
          />

          <UniversalBlock
            headerText="Chrome Extension"
            descriptionText="Welcome to the future of job hunting! Our Chrome browser extension, powered by artificial intelligence, will assist you in creating personalized resumes in just a few clicks, tailored to each job description."
            type="left-image"
            imageSrc="./images/chromeExtensionBlock.webp"
            withChrome
          />
        </>
      ) : (
        <>
          <TrustedByBlock language="en" />

          <div className="short-videos-block">
            <p>
              Short videos that help you hire
              the right candidates in
              <span> days </span>
              not months.
            </p>
            <picture>
              <source media={`(max-width: ${CONFIG.mobileWidth})`} srcSet="./images/mobile/people.png" />
              <img alt="3 people" src="./images/people.webp" />
            </picture>
          </div>

          <UniversalBlock
            headerText="AI-Powered Matching"
            descriptionText="The platform uses an AI-based matching algorithm to help employers find the right match quickly and easily. The algorithm takes into account many factors to match employers with the most suitable candidates."
            type="left-image"
            imageSrc="./images/aiBlockImage.png"
            mobileImageSrc="./images/mobile/aiBlockImage.png"
          />

          <div className="autointerview-block">
            <div className="image-block">
              <picture>
                {/* @todo: add this */}
                <source media={`(max-width: ${CONFIG.mobileWidth})`} srcSet="./images/mobile/showcaseImage.png" />
                <img alt="2 iphone avatars" src="./images/autointerviewImage.webp" />
              </picture>
            </div>
            <div className="text-block">
              <div>
                <p className="header-text">
                  Autointerview™
                </p>
                <span className="gray-text">
                  Streamlining hiring with automated scheduling, AI screening, and video interviews.
                  Real-time collaboration, data-driven insights, and seamless integration make
                  it efficient and secure.
                  Elevate your hiring process with Autointerview.
                </span>
              </div>
            </div>
          </div>

          <div className="showcase-block">
            <div className="text-block">
              <div>
                <p className="header-text">
                  Showcase your workplace
                  with impactful videos
                </p>
                <span className="gray-text">
                  Karizma&apos;s app streamlines the hiring process by letting
                  employers scroll through candidate profiles, review their
                  CVs and experience, and connect via
                  the messaging feature. This creates a seamless
                  experience for both employers and candidates, enabling
                  effective recruitment for the best job matches.
                </span>
                <StoreButtons userType={userType} />
              </div>
            </div>
            <div className="image-block">
              <picture>
                <source media={`(max-width: ${CONFIG.mobileWidth})`} srcSet="./images/mobile/showcaseImage.png" />
                <img alt="2 iphone avatars" src="./images/showcaseImage.webp" />
              </picture>
            </div>
          </div>

          <UniversalBlock
            headerText="Direct Messaging"
            descriptionText="Employers can swipe through candidate videos and chat directly with potential hires. Candidates can also view short videos of employers and learn about the workplace and team."
            type="left-image"
            imageSrc="./images/messagingImage.webp"
            mobileImageSrc="./images/mobile/messagingImage.png"
          />

          <UniversalBlock
            headerText="Web Portal"
            descriptionText="Karizma's analytics platform to track job applications and the hiring process. The platform provides valuable insights to make data-driven decisions and optimize the hiring process, improving efficiency and finding the best candidates."
            type="right-image"
            imageSrc="./images/webPortalImage.png"
            mobileImageSrc="./images/mobile/webPortalImage.png"
          />

          <div className="serving-others-block">
            <div>
              <img alt="2 people at PC" src="./images/servingThoseImage.webp" />
            </div>
            <div className="right-block">
              <p className="header-text">
                Serving those,
                serving others
              </p>
              <span className="gray-text">
                Our mission at Karizma is to serve those serving
                others. We strive to simplify the hiring process
                for small businesses by providing an innovative,
                mobile platform that connects employers with
                top talent quickly and efficiently. By doing so,
                we aim to empower businesses to grow and
                thrive while also creating meaningful
                employment opportunities for job seekers.
              </span>
            </div>
          </div>
        </>
      )}

      <div className="reviews-block">
        <p>What Users Are Saying</p>
        {width <= Number(CONFIG.mobileWidth.split('px')[0]) ? (
          <ReviewsListMobile userType={userType} language="en" />
        ) : (
          <ReviewsList userType={userType} language="en" />
        )}
      </div>

      {userType === UserType.employers ? (
        <ContactUsBlock />
      ) : null}

      <div className="ready-to-exp-block">
        <div className="left-block">
          <div className="qr-code">
            <img alt="qr-code" src={`./images/qrCode${userType === UserType.candidates ? 'Candidate' : 'Employer'}.png`} />
          </div>
          {userType === UserType.candidates ? (
            <p>
              Download the App,
              and scroll up for your
              <br />
              next
              <span className="whisper-text"> adventure!</span>
            </p>
          ) : (
            <p>
              Ready to experience a
              <br />
              <span> better </span>
              way to hire?
            </p>
          )}
          <StoreButtons userType={userType} />
        </div>
        <div className="right-block">
          {userType === UserType.candidates ? (
            <img alt="2 persons at work" src="./images/downloadTheAppImage.webp" />
          ) : (
            <picture>
              <source media={`(max-width: ${CONFIG.mobileWidth})`} srcSet="./images/mobile/readyToExpImage.png" />
              <img alt="5 persons at work" src="./images/readyToExpImage.webp" />
            </picture>
          )}
        </div>
      </div>
    </>
  )
}

export default USABodyContent
