import React from 'react'

interface LinkedinProps {
  color: string
}

function Linkedin({ color = '#7D4AFB' }: LinkedinProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      fill="none"
      viewBox="0 0 41 40"
    >
      <g clipPath="url(#clip0_5521_4877)">
        <path
          fill={color}
          d="M30.723 8H10.272C9.292 8 8.5 8.773 8.5 9.73v20.536c0 .956.792 1.734 1.772 1.734h20.451c.98 0 1.777-.778 1.777-1.73V9.73c0-.957-.797-1.73-1.777-1.73zM15.62 28.452h-3.562V16.995h3.562v11.457zm-1.78-13.018a2.064 2.064 0 110-4.125 2.063 2.063 0 010 4.125zm15.112 13.018h-3.558v-5.57c0-1.326-.024-3.037-1.852-3.037-1.851 0-2.133 1.449-2.133 2.944v5.663h-3.553V16.995h3.413v1.566h.047c.473-.9 1.636-1.852 3.365-1.852 3.605 0 4.27 2.372 4.27 5.457v6.286z"
        />
      </g>
      <defs>
        <clipPath id="clip0_5521_4877">
          <path
            fill="#fff"
            d="M0 0H24V24H0z"
            transform="translate(8.5 8)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Linkedin
