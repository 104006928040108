import React from 'react'
import { Link } from 'react-router-dom'

import { UserType } from '../App'
import Button from './Button'
import useWindowDimensions from '../hooks/useWindowDimensions'
import CONFIG from '../config.json'

interface HeaderProps {
  userType: keyof typeof UserType,
  region: string,
  language: string,
  changeLanguage: (newLang: string) => void,
}

function Header({
  userType, region, changeLanguage, language,
}: Partial<HeaderProps>): ReturnType<React.FC> {
  const { width } = useWindowDimensions()

  return (
    <div className="header">
      <div className="logo-image">
        <Link to="/">
          <img width="150px" alt="Karizma Logo" src={`./images/logo${userType === UserType.candidates ? 'White' : ''}.webp`} />
        </Link>
        {width <= Number(CONFIG.mobileWidth.split('px')[0]) ? (
          <div className="languages">
            <span onClick={() => changeLanguage('en')} className={language === 'en' ? 'active' : 'inactive'}>EN</span>
            {region === 'ar' ? (
              <span
                onClick={() => {
                  changeLanguage('ar')
                }}
                className={region === 'ar' && language === 'ar' ? 'active' : 'inactive'}
              >
                AR
              </span>
            ) : null}
          </div>
        ) : null}
      </div>
      <div className="right-navigation">
        <div className={`languages ${width <= Number(CONFIG.mobileWidth.split('px')[0]) ? 'hidden' : ''}`}>
          <span onClick={() => changeLanguage('en')} className={language === 'en' ? 'active' : 'inactive'}>EN</span>
          {region === 'ar' ? (
            <span
              onClick={() => {
                changeLanguage('ar')
              }}
              className={region === 'ar' && language === 'ar' ? 'active' : 'inactive'}
            >
              AR
            </span>
          ) : null}
        </div>
        <div className="sign-buttons">
          {userType === UserType.candidates ? (
            <Button
              background="gradient"
              label={region === 'ar' && language === 'ar' ? 'تحميل ملحق كروم' : 'Download Chrome Extension'}
              onClick={() => {}}
            />
          ) : (
            <>
              <Button
                background="white"
                label={region === 'ar' && language === 'ar' ? 'اشتراك' : 'Sign Up'}
                onClick={() => window.open(CONFIG.signUpUrl)}
              />
              <Button
                background="gradient"
                label={region === 'ar' && language === 'ar' ? 'تسجيل الدخول' : 'Sign In'}
                onClick={() => window.open(CONFIG.signInUrl)}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
