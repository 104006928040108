/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable no-console */
/* eslint-disable no-trailing-spaces */
/* eslint-disable max-len */
import React from 'react'

import { UserType } from '../App'
import { Carousel, CarouselItem } from './ReviewsCarousel'
import CONFIG from '../config.json'
import useWindowDimensions from '../hooks/useWindowDimensions'

interface ReviewsListProps {
  userType: keyof typeof UserType,
  language?: string,
}

export const CandidatesReviews = [{
  id: 1,
  englishText: "Thanks to Karizma's short video profiles, finding the perfect server for our restaurant was a breeze. The old alternative of sifting through resumes and scheduling endless interviews was exhausting!",
  arabicText: 'لقد جعلت كاريزما العثور على باريستا المناسب أمرًا سهلاً لمقهانا. ساعدتنا مقاطع الفيديو القصيرة في تحديد الشخص المناسب بسرعة، مما جعل البديل القديم المتمثل في غربلة السير الذاتية وإجراء المقابلات الهاتفية شيئًا من الماضي!',
}, {
  id: 2,
  englishText: "Karizma's platform changed the game for our hotel. Short video profiles allowed us to quickly identify the best candidate for our front desk staff, saving us time and energy.",
  arabicText: 'لقد غيرت منصة كاريزما قواعد اللعبة بالنسبة لفندقنا. أتاحت لنا ملفات تعريف الفيديو القصيرة التعرف بسرعة على أفضل مرشح لموظفي مكتب الاستقبال لدينا، مما يوفر لنا الوقت والطاقة.',
}, {
  id: 3,
  englishText: 'Karizma made finding the right barista easy for our coffee shop. Short video profiles helped us to identify the best fit quickly, making the old alternative of sifting through resumes and conducting phone interviews a thing of the past!',
  arabicText: 'بفضل مقاطع الفيديو القصيرة الخاصة بـ Karizma، أصبح العثور على الخادم المثالي لمطعمنا أمرًا سهلاً. كان البديل القديم المتمثل في غربلة السير الذاتية وجدولة المقابلات التي لا نهاية لها أمرًا مرهقًا!',
}, {
  id: 4,
  englishText: "Thanks to Karizma's short video profiles, finding the perfect server for our restaurant was a breeze. The old alternative of sifting through resumes and scheduling endless interviews was exhausting!",
  arabicText: 'لقد جعلت كاريزما العثور على باريستا المناسب أمرًا سهلاً لمقهانا. ساعدتنا مقاطع الفيديو القصيرة في تحديد الشخص المناسب بسرعة، مما جعل البديل القديم المتمثل في غربلة السير الذاتية وإجراء المقابلات الهاتفية شيئًا من الماضي!',
}, {
  id: 5,
  englishText: "Karizma's platform changed the game for our hotel. Short video profiles allowed us to quickly identify the best candidate for our front desk staff, saving us time and energy.",
  arabicText: 'لقد غيرت منصة كاريزما قواعد اللعبة بالنسبة لفندقنا. أتاحت لنا ملفات تعريف الفيديو القصيرة التعرف بسرعة على أفضل مرشح لموظفي مكتب الاستقبال لدينا، مما يوفر لنا الوقت والطاقة.',
}, {
  id: 6,
  englishText: 'Karizma made finding the right barista easy for our coffee shop. Short video profiles helped us to identify the best fit quickly, making the old alternative of sifting through resumes and conducting phone interviews a thing of the past!',
  arabicText: 'بفضل مقاطع الفيديو القصيرة الخاصة بـ Karizma، أصبح العثور على الخادم المثالي لمطعمنا أمرًا سهلاً. كان البديل القديم المتمثل في غربلة السير الذاتية وجدولة المقابلات التي لا نهاية لها أمرًا مرهقًا!',
}, {
  id: 7,
  englishText: "Thanks to Karizma's short video profiles, finding the perfect server for our restaurant was a breeze. The old alternative of sifting through resumes and scheduling endless interviews was exhausting!",
  arabicText: 'لقد جعلت كاريزما العثور على باريستا المناسب أمرًا سهلاً لمقهانا. ساعدتنا مقاطع الفيديو القصيرة في تحديد الشخص المناسب بسرعة، مما جعل البديل القديم المتمثل في غربلة السير الذاتية وإجراء المقابلات الهاتفية شيئًا من الماضي!',
}, {
  id: 8,
  englishText: "Karizma's platform changed the game for our hotel. Short video profiles allowed us to quickly identify the best candidate for our front desk staff, saving us time and energy.",
  arabicText: 'لقد غيرت منصة كاريزما قواعد اللعبة بالنسبة لفندقنا. أتاحت لنا ملفات تعريف الفيديو القصيرة التعرف بسرعة على أفضل مرشح لموظفي مكتب الاستقبال لدينا، مما يوفر لنا الوقت والطاقة.',
}, {
  id: 9,
  englishText: 'Karizma made finding the right barista easy for our coffee shop. Short video profiles helped us to identify the best fit quickly, making the old alternative of sifting through resumes and conducting phone interviews a thing of the past!',
  arabicText: 'بفضل مقاطع الفيديو القصيرة الخاصة بـ Karizma، أصبح العثور على الخادم المثالي لمطعمنا أمرًا سهلاً. كان البديل القديم المتمثل في غربلة السير الذاتية وجدولة المقابلات التي لا نهاية لها أمرًا مرهقًا!',
}]

export const EmployersReviews = [{
  id: 1,
  englishText: "Thanks to Karizma's short video profiles, finding the perfect server for our restaurant was a breeze. The old alternative of sifting through resumes and scheduling endless interviews was exhausting!",
  arabicText: 'لقد جعلت كاريزما العثور على باريستا المناسب أمرًا سهلاً لمقهانا. ساعدتنا مقاطع الفيديو القصيرة في تحديد الشخص المناسب بسرعة، مما جعل البديل القديم المتمثل في غربلة السير الذاتية وإجراء المقابلات الهاتفية شيئًا من الماضي!',
}, {
  id: 2,
  englishText: 'Karizmas platform changed the game for our hotel. Short video profiles allowed us to quickly identify the best candidate for our front desk staff, saving us time and energy.',
  arabicText: 'لقد غيرت منصة كاريزما قواعد اللعبة بالنسبة لفندقنا. أتاحت لنا ملفات تعريف الفيديو القصيرة التعرف بسرعة على أفضل مرشح لموظفي مكتب الاستقبال لدينا، مما يوفر لنا الوقت والطاقة.',
}, {
  id: 3,
  englishText: 'Karizma made finding the right barista easy for our coffee shop. Short video profiles helped us to identify the best fit quickly, making the old alternative of sifting through resumes and conducting phone interviews a thing of the past!',
  arabicText: 'بفضل مقاطع الفيديو القصيرة الخاصة بـ Karizma، أصبح العثور على الخادم المثالي لمطعمنا أمرًا سهلاً. كان البديل القديم المتمثل في غربلة السير الذاتية وجدولة المقابلات التي لا نهاية لها أمرًا مرهقًا!',
}, {
  id: 4,
  englishText: "Thanks to Karizma's short video profiles, finding the perfect server for our restaurant was a breeze. The old alternative of sifting through resumes and scheduling endless interviews was exhausting!",
  arabicText: 'لقد جعلت كاريزما العثور على باريستا المناسب أمرًا سهلاً لمقهانا. ساعدتنا مقاطع الفيديو القصيرة في تحديد الشخص المناسب بسرعة، مما جعل البديل القديم المتمثل في غربلة السير الذاتية وإجراء المقابلات الهاتفية شيئًا من الماضي!',
}, {
  id: 5,
  englishText: 'Karizmas platform changed the game for our hotel. Short video profiles allowed us to quickly identify the best candidate for our front desk staff, saving us time and energy.',
  arabicText: 'لقد غيرت منصة كاريزما قواعد اللعبة بالنسبة لفندقنا. أتاحت لنا ملفات تعريف الفيديو القصيرة التعرف بسرعة على أفضل مرشح لموظفي مكتب الاستقبال لدينا، مما يوفر لنا الوقت والطاقة.',
}, {
  id: 6,
  englishText: 'Karizma made finding the right barista easy for our coffee shop. Short video profiles helped us to identify the best fit quickly, making the old alternative of sifting through resumes and conducting phone interviews a thing of the past!',
  arabicText: 'بفضل مقاطع الفيديو القصيرة الخاصة بـ Karizma، أصبح العثور على الخادم المثالي لمطعمنا أمرًا سهلاً. كان البديل القديم المتمثل في غربلة السير الذاتية وجدولة المقابلات التي لا نهاية لها أمرًا مرهقًا!',
}, {
  id: 7,
  englishText: "Thanks to Karizma's short video profiles, finding the perfect server for our restaurant was a breeze. The old alternative of sifting through resumes and scheduling endless interviews was exhausting!",
  arabicText: 'لقد جعلت كاريزما العثور على باريستا المناسب أمرًا سهلاً لمقهانا. ساعدتنا مقاطع الفيديو القصيرة في تحديد الشخص المناسب بسرعة، مما جعل البديل القديم المتمثل في غربلة السير الذاتية وإجراء المقابلات الهاتفية شيئًا من الماضي!',
}, {
  id: 8,
  englishText: 'Karizmas platform changed the game for our hotel. Short video profiles allowed us to quickly identify the best candidate for our front desk staff, saving us time and energy.',
  arabicText: 'لقد غيرت منصة كاريزما قواعد اللعبة بالنسبة لفندقنا. أتاحت لنا ملفات تعريف الفيديو القصيرة التعرف بسرعة على أفضل مرشح لموظفي مكتب الاستقبال لدينا، مما يوفر لنا الوقت والطاقة.',
}, {
  id: 9,
  englishText: 'Karizma made finding the right barista easy for our coffee shop. Short video profiles helped us to identify the best fit quickly, making the old alternative of sifting through resumes and conducting phone interviews a thing of the past!',
  arabicText: 'بفضل مقاطع الفيديو القصيرة الخاصة بـ Karizma، أصبح العثور على الخادم المثالي لمطعمنا أمرًا سهلاً. كان البديل القديم المتمثل في غربلة السير الذاتية وجدولة المقابلات التي لا نهاية لها أمرًا مرهقًا!',
}]

function ReviewsList({ userType, language }: ReviewsListProps) {
  const { width } = useWindowDimensions()

  if (userType === UserType.candidates) {
    return (
      <Carousel
        items={width <= Number(CONFIG.mobileWidth.split('px')[0]) ? CandidatesReviews.slice(0, 6) : CandidatesReviews}
        renderItem={({ item, isSnapPoint }) => (
          <CarouselItem
            key={item.id}
            language={language}
            englishText={item.englishText}
            arabicText={item.arabicText}
            isSnapPoint={isSnapPoint}
          />
        )}
      />
    )
  }

  return (
    <Carousel
      items={width <= Number(CONFIG.mobileWidth.split('px')[0]) ? EmployersReviews.slice(0, 6) : EmployersReviews}
      renderItem={({ item, isSnapPoint }) => (
        <CarouselItem
          key={item.id}
          language={language}
          englishText={item.englishText}
          arabicText={item.arabicText}
          isSnapPoint={isSnapPoint}
        />
      )}
    />
  )
}

export default ReviewsList
