/* eslint-disable react/no-array-index-key */
import React, { useRef, useState } from 'react'
import Slider from 'react-slick'

import { UserType } from 'App'
import { ArrowLeft, ArrowRight } from 'icons'
import { EmployersReviews, CandidatesReviews } from './ReviewsList'
import { CarouselItem } from './ReviewsCarousel'

interface ReviewsListMobileProps {
  language: string,
  userType: string,
}

const InitialSlide = 3

function ReviewsListMobile({ language, userType }: ReviewsListMobileProps) {
  const [activePageIndex, setActivePage] = useState(InitialSlide)
  const sliderRef = useRef<any>(null)

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'reviews-list-mobile',
    centerMode: true,
    arrow: true,
    initialSlide: InitialSlide,
    centerPadding: '15px',
    infinite: false,
  }

  return (
    <>
      {userType === UserType.employers ? (
        <Slider
          ref={sliderRef}
          {...settings}
          afterChange={(current) => setActivePage(current)}
        >
          {EmployersReviews.slice(0, 7).map((review) => (
            <CarouselItem
              key={review.id}
              language={language}
              englishText={review.englishText}
              arabicText={review.arabicText}
              isSnapPoint={{}}
            />
          ))}
        </Slider>
      ) : (
        <Slider
          ref={sliderRef}
          {...settings}
          afterChange={(current) => setActivePage(current)}
        >
          {CandidatesReviews.slice(0, 7).map((review) => (
            <CarouselItem
              key={review.id}
              language={language}
              englishText={review.englishText}
              arabicText={review.arabicText}
              isSnapPoint={{}}
            />
          ))}
        </Slider>
      )}
      <div className="carousel-controls">
        <button
          type="button"
          className={`carousel-button ${activePageIndex === 0 ? 'disabled' : ''}`}
          onClick={() => sliderRef.current.slickGoTo(activePageIndex - 1, false)}
        >
          <ArrowLeft />
        </button>
        <div className="carousel-points">
          {EmployersReviews.slice(0, 7).map((_, i) => (
            <div key={i} onClick={() => sliderRef.current.slickGoTo(i, false)} className={`carousel-point ${activePageIndex === i ? 'selected' : ''}`} />
          ))}
        </div>
        <button
          type="button"
          className={`carousel-button ${activePageIndex === 7 - 1 ? 'disabled' : ''}`}
          onClick={() => sliderRef.current.slickGoTo(activePageIndex + 1, false)}
        >
          <ArrowRight />
        </button>
      </div>
    </>
  )
}

export default ReviewsListMobile
