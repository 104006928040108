/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React from 'react'

import { UserType } from 'App'
import useWindowDimensions from '../hooks/useWindowDimensions'
import StoreButtons from './StoreButtons'
import CONFIG from '../config.json'
import ContactUsBlock from './blocks/ContactUsBlock'
import UniversalBlock from './blocks/UniversalBlock'
import ReviewsList from './ReviewsList'
import TrustedByBlock from './blocks/TrustedByBlock'
import ReviewsListMobile from './ReviewsListMobile'

function ArabicBodyContentAR({ userType }: any): ReturnType<React.FC> {
  const { width } = useWindowDimensions()

  return (
    <div>
      <div className="hire-block">
        <div className="left-block">
          {userType === UserType.candidates ? (
            <p>
              الطريقه
              <span className="malibu-text"> الأسرع </span>
              للعثور على وظيفة أحلامك
            </p>
          ) : (
            <p>
              <span className="purple-text">أسهل</span>
              <span> طريقة لتوظيف أفضل المرشحين</span>
            </p>
          )}
          <StoreButtons userType={userType} />
          <div className="qr-code">
            <img alt="qr-code" src={`./images/qrCode${userType === UserType.candidates ? 'Candidate' : 'Employer'}.png`} />
          </div>
        </div>
        <div className="right-block">
          <picture>
            <source media={`(max-width: ${CONFIG.mobileWidth})`} srcSet="./images/mobile/arabic/hireBlockImage.png" />
            <img
              className={userType === UserType.candidates ? 'inactive' : 'active'}
              alt="people at work"
              src="./images/arabic/hireBlockImage.png"
            />
          </picture>
          <picture>
            <source media={`(max-width: ${CONFIG.mobileWidth})`} srcSet="./images/mobile/arabic/hireBlockImageCandidates.png" />
            <img
              className={userType === UserType.candidates ? 'active' : 'inactive'}
              alt="people at work"
              src="./images/arabic/hireBlockImageCandidates.png"
            />
          </picture>
        </div>
      </div>

      {userType === UserType.candidates ? (
        <>
          <div className="join-thousands-block">
            <p>
              الباحثين عن العمل
            </p>
            <div className="dream-members">
              {width <= Number(CONFIG.mobileWidth.split('px')[0]) ? (
                <>
                  <div>
                    <img alt="Person at Work" src="./images/dreamMember3.png" />
                  </div>
                  <div>
                    <img alt="Person at Work" src="./images/dreamMember4.png" />
                  </div>
                </>
              ) : (
                new Array(6).fill(null).map((_, index) => (
                  <div key={index}>
                    <img alt="Person at Work" src={`./images/dreamMember${index + 1}.png`} />
                  </div>
                ))
              )}
            </div>
          </div>

          <div className="looking-job-block">
            <p>
              تبحث عن وظيفة؟ اترك انطباعًا دائمًا من خلال السيرة الذاتية بالفيديو! يتيح لك تطبيق البحث عن عمل الخاص بنا تسجيل مقطع فيديو موجز لتعريف نفسك لأصحاب العمل المحتملين. اتخذ الخطوة الأولى نحو مهنة أحلامك من خلال سيرة ذاتية بالفيديو!
            </p>
          </div>

          <UniversalBlock
            headerText="ماتراه هو ما ستحصل عليه"
            descriptionText="لقد ولت الأيام التي كنت تقرأ فيها الأوصاف الوظيفية التي لا نهاية لها. مع كاريزما، سوف تتمكن من رؤية المكان الذي ستعمل فيه، والأشخاص الذين ستعمل معهم، والتعرف على بيئة العمل بشكل عام"
            type="left-image"
            imageSrc="./images/arabic/whatYouSeeBlockImage.png"
            mobileImageSrc="./images/mobile/arabic/whatYouSeeBlockImage.png"
            withStoreButtons
          />

          <UniversalBlock
            headerText="الظلال من قبل أصحاب العمل هو شيء من الماضي"
            descriptionText="لا يوجد شيء يستحق أن تُترك دون معرفة حالة طلبك. ولهذا السبب جعلنا من مهمتنا في كاريزما القضاء على الظلال من قبل أصحاب العمل. سوف تتمكن من رؤية حالة طلبك في الوقت الفعلي أثناء تحركه خلال العملية."
            type="right-image"
            imageSrc="./images/arabic/ghostingBlockImage.png"
            mobileImageSrc="./images/mobile/arabic/ghostingBlockImage.png"
          />

          <UniversalBlock
            headerText="ملحق كروم"
            descriptionText="مرحبًا بكم في مستقبل البحث عن عمل! سيساعدك ملحق متصفح Chrome، المدعوم بالذكاء الاصطناعي، في إنشاء سيرة ذاتية مخصصة ببضع نقرات فقط، ومصمم خصيصًا لكل وصف وظيفي"
            type="left-image"
            imageSrc="./images/arabic/chromeExtensionBlock.webp"
            withChrome
          />
        </>
      ) : (
        <>
          <TrustedByBlock language="ar" />

          <div className="short-videos-block">
            <p>
              مقاطع فيديو قصيرة تساعدك على توظيف المرشحين المناسبين في
              <span className="purple-text"> أيام </span>
              وليس أشهر.
            </p>
            <picture>
              <source media={`(max-width: ${CONFIG.mobileWidth})`} srcSet="./images/mobile/arabic/people.png" />
              <img alt="3 people" src="./images/arabic/people.png" />
            </picture>
          </div>

          <UniversalBlock
            headerText="مطابقة مدعومة بالذكاء الاصطناعي"
            descriptionText="تستخدم المنصة خوارزمية مطابقة قائمة على الذكاء الاصطناعي لمساعدة أصحاب العمل في العثور على الشريك المناسب بسرعة وسهولة. تأخذ الخوارزمية في الاعتبار العديد من العوامل لمطابقة أصحاب العمل مع المرشحين الأكثر ملاءمة."
            type="left-image"
            imageSrc="./images/arabic/aiBlockImage.png"
            mobileImageSrc="./images/mobile/arabic/aiBlockImage.png"
          />

          <div className="showcase-block">
            <div className="text-block">
              <div>
                <p className="header-text">
                  اعرض مكان عملك بمقاطع فيديو مؤثرة
                </p>
                <span className="gray-text">
                  يعمل تطبيق Karizma على تبسيط عملية التوظيف من خلال السماح لأصحاب العمل بالتمرير عبر ملفات تعريف المرشحين ومراجعة سيرهم الذاتية وخبراتهم والتواصل عبر ميزة المراسلة. وهذا يخلق تجربة سلسة لكل من أصحاب العمل والمرشحين، مما يتيح التوظيف الفعال لأفضل الوظائف المتطابقة.
                </span>
                <StoreButtons userType={userType} />
              </div>
            </div>
            <div className="image-block">
              <picture>
                <source media={`(max-width: ${CONFIG.mobileWidth})`} srcSet="./images/mobile/arabic/showcaseImage.png" />
                <img alt="2 iphone avatars" src="./images/arabic/showcaseImage.png" />
              </picture>
            </div>
          </div>

          <UniversalBlock
            headerText="المراسلة المباشرة"
            descriptionText="يمكن لأصحاب العمل تصفح مقاطع الفيديو المرشحة والدردشة مباشرة مع الموظفين المحتملين. يمكن للمرشحين أيضًا مشاهدة مقاطع فيديو قصيرة لأصحاب العمل والتعرف على مكان العمل والفريق."
            type="left-image"
            imageSrc="./images/arabic/messagingImage.png"
            mobileImageSrc="./images/mobile/arabic/messagingImage.png"
          />

          <UniversalBlock
            headerText="بوابة الويب"
            descriptionText="منصة تحليلات Karizma لتتبع طلبات العمل وعملية التوظيف. توفر المنصة رؤى قيمة لاتخاذ قرارات تعتمد على البيانات وتحسين عملية التوظيف وتحسين الكفاءة وإيجاد أفضل المرشحين."
            type="right-image"
            imageSrc="./images/arabic/webPortalImage.png"
            mobileImageSrc="./images/mobile/arabic/webPortalImage.png"
          />

          <div className="serving-others-block">
            <div>
              <img alt="2 people at PC" src="./images/arabic/servingThoseImage.png" />
            </div>
            <div className="right-block">
              <p className="header-text">
                خدمة هؤلاء، خدمة الآخرين
              </p>
              <span className="gray-text">
                مهمتنا في كاريزما هي خدمة أولئك الذين يخدمون الآخرين. نحن نسعى جاهدين لتبسيط عملية التوظيف للشركات الصغيرة من خلال توفير منصة متنقلة مبتكرة تربط أصحاب العمل بأفضل المواهب بسرعة وكفاءة. ومن خلال القيام بذلك، نهدف إلى تمكين الشركات من النمو والازدهار مع خلق فرص عمل هادفة للباحثين عن عمل.
              </span>
            </div>
          </div>
        </>
      )}

      <div className="reviews-block">
        <p>ما يقوله المستخدمون</p>
        {width <= Number(CONFIG.mobileWidth.split('px')[0]) ? (
          <ReviewsListMobile userType={userType} language="ar" />
        ) : (
          <ReviewsList userType={userType} language="ar" />
        )}
      </div>

      {userType === UserType.employers ? (
        <ContactUsBlock language="ar" />
      ) : null}

      <div className="ready-to-exp-block">
        <div className="left-block">
          <div className="qr-code">
            <img alt="qr-code" src={`./images/qrCode${userType === UserType.candidates ? 'Candidate' : 'Employer'}.png`} />
          </div>
          {userType === UserType.candidates ? (
            <p>
              قم بتنزيل التطبيق وانتقل للأعلى لمغامرتك
              <span className="malibu-text"> القادمة !</span>
            </p>
          ) : (
            <p>
              هل أنت مستعد لتجربة طريقة
              <span className="purple-text">أفضل </span>
              للتوظيف؟
            </p>
          )}
          <StoreButtons userType={userType} />
        </div>
        <div className="right-block">
          <picture>
            <source media={`(max-width: ${CONFIG.mobileWidth})`} srcSet="./images/mobile/arabic/downloadTheAppImage.png" />
            <img
              className={userType === UserType.candidates ? 'inactive' : 'active'}
              alt="2 persons at work"
              src="./images/arabic/downloadTheAppImage.webp"
            />
          </picture>
          <picture>
            <source media={`(max-width: ${CONFIG.mobileWidth})`} srcSet="./images/mobile/arabic/readyToExpImage.png" />
            <img
              className={userType === UserType.candidates ? 'active' : 'inactive'}
              alt="5 persons at work"
              src="./images/arabic/readyToExpImage.png"
            />
          </picture>

        </div>
      </div>
    </div>
  )
}

export default ArabicBodyContentAR
