import React from 'react'

function ArrowLeft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#54585F"
        fillRule="evenodd"
        d="M13.09 3.577a.833.833 0 010 1.179L7.844 10l5.244 5.244a.833.833 0 11-1.178 1.178L6.077 10.59a.833.833 0 010-1.178l5.834-5.834a.833.833 0 011.178 0z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default ArrowLeft
