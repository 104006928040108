/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import * as emailjs from '@emailjs/browser'

import {
  Toggle,
  Header,
  Footer,
  Modal,
} from 'components'
import { Home, PrivacyPolicy, TermsOfService } from './pages'
import CONFIG from './config.json'

export const UserType = {
  employers: 'employers',
  candidates: 'candidates',
} as const

export type UserTypes = typeof UserType[keyof typeof UserType]

export const Regions = {
  en: 'USA',
  ar: 'Saudi Arabia',
}

emailjs.init(CONFIG.emailJsPublic)

function App() {
  const [userType, setUserType] = useState<UserTypes>(UserType.employers)
  const [region, setRegion] = useState<string>('en')
  const [language, setLanguage] = useState<string>('en')
  const [isRegionModalVisible, setIsRegionModalVisible] = useState(false)

  useEffect(() => {
    const link: any = document.querySelector("link[rel*='icon']")
    link.href = userType === UserType.candidates ? 'faviconCandidate.ico' : 'favicon.ico'
  }, [userType])

  return (
    <Router>
      <div className={`container${userType === UserType.candidates ? ' candidates' : ''} ${region === 'ar' && language === 'ar' ? 'arabic' : ''}`}>
        <Header
          userType={userType}
          region={region}
          changeLanguage={setLanguage}
          language={language}
        />
        <Routes>
          <Route path="/" element={<Home region={region} userType={userType} language={language} setUserType={setUserType} />} />
          <Route path="/termsofservice" Component={TermsOfService} />
          <Route path="/privacy" Component={PrivacyPolicy} />
        </Routes>
        <Footer
          userType={userType}
          region={region}
          onChangeRegion={() => setIsRegionModalVisible(true)}
          changeLanguage={setLanguage}
          language={language}
        />
      </div>
      <Modal
        visible={isRegionModalVisible}
        onHide={() => setIsRegionModalVisible(false)}
      >
        <div>
          <p className="modal-header">{region === 'ar' && language === 'ar' ? 'قم بتغيير منطقتك' : 'Change your region'}</p>
          <Toggle
            active={region}
            onToggle={setRegion}
            changeLanguage={setLanguage}
            type="languages"
            language={language}
          />
        </div>
      </Modal>
    </Router>
  )
}

export default App
