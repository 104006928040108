/* eslint-disable no-nested-ternary */
import React from 'react'

import {
  Toggle,
  USABodyContent,
  ArabicBodyContentAR,
  ArabicBodyContentEN,
} from 'components'

export const UserType = {
  employers: 'employers',
  candidates: 'candidates',
} as const

export type UserTypes = typeof UserType[keyof typeof UserType]

export const Regions = {
  en: 'USA',
  ar: 'Saudi Arabia',
}

function Home({
  region, userType, language, setUserType,
}): ReturnType<React.FC> {
  return (
    <>
      <Toggle
        active={userType}
        onToggle={setUserType}
        type="userTypes"
        language={language}
      />
      {region === 'en' ? (
        <USABodyContent userType={userType} />
      ) : (language === 'en' ? <ArabicBodyContentEN userType={userType} /> : <ArabicBodyContentAR userType={userType} />)}
    </>
  )
}

export default Home
