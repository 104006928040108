import React from 'react'

import StoreButtons from 'components/StoreButtons'
import CONFIG from '../../config.json'
import { UserType } from '../../App'

interface UniversalBlockProps {
  withStoreButtons?: boolean,
  withChrome?: boolean,
  type: 'left-image' | 'right-image',
  imageSrc: string,
  mobileImageSrc?: string,
  headerText: string,
  descriptionText: string,
  userType?: keyof typeof UserType,
}

function UniversalBlock({
  withStoreButtons, withChrome, imageSrc, mobileImageSrc,
  headerText, descriptionText, type, userType,
}: UniversalBlockProps) {
  return (
    <div className={`universal-block ${type === 'right-image' ? 'not-reverse' : ''}`}>
      {type === 'left-image' ? (
        <div className="image-block">
          <picture>
            {mobileImageSrc ? <source media={`(max-width: ${CONFIG.mobileWidth})`} srcSet={mobileImageSrc} /> : null}
            <img alt="notifications with avatar" src={imageSrc} />
          </picture>
        </div>
      ) : null}
      <div className="text-block">
        <div>
          <p className="header-text">{headerText}</p>
          <span className="gray-text">
            {descriptionText}
          </span>
          {withStoreButtons ? (
            <StoreButtons userType={userType} />
          ) : null}
          {withChrome ? (
            <div className="chrome-image">
              <img alt="Chrome Extension" src="./images/chromExtension.webp" />
            </div>
          ) : null}
        </div>
      </div>
      {type === 'right-image' ? (
        <div className="image-block">
          <picture>
            <source media={`(max-width: ${CONFIG.mobileWidth})`} srcSet={mobileImageSrc} />
            <img alt="notifications with avatar" src={imageSrc} />
          </picture>
        </div>
      ) : null}
    </div>
  )
}

export default UniversalBlock
