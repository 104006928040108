import React from 'react'
import { Link } from 'react-router-dom'

import {
  ArrowDown, Worldwide, Insta, Linkedin, Tiktok,
} from 'icons'
import { UserType } from '../App'

interface FooterProps {
  userType: keyof typeof UserType,
  region: string,
  onChangeRegion: () => void,
  changeLanguage: (newLang: string) => void,
  language: string,
}

function Footer({
  userType, region, onChangeRegion, changeLanguage, language,
}: Partial<FooterProps>): ReturnType<React.FC> {
  return (
    <div className="footer">
      <Link to="/">
        <div className="karizma-logo">
          <img width="150px" alt="Karizma Logo" src={`./images/logo${userType === UserType.candidates ? 'White' : ''}.webp`} />
          <span>© 2023 by Karizma, Inc.</span>
        </div>
      </Link>

      <div className="footer-terms">
        <Link to="/privacy">
          <p>{region === 'ar' && language === 'ar' ? 'سياسة الخصوصية' : 'Privacy Policy'}</p>
        </Link>
        <Link to="/termsofservice">
          <p>{region === 'ar' && language === 'ar' ? 'شروط الخدمة' : 'Terms of Service'}</p>
        </Link>
      </div>

      <div className="social">
        <div className="social-icons">
          <Insta color={userType === UserType.candidates ? '#4AC8FB' : '#7D4AFB'} />
          <Linkedin color={userType === UserType.candidates ? '#4AC8FB' : '#7D4AFB'} />
          <Tiktok color={userType === UserType.candidates ? '#4AC8FB' : '#7D4AFB'} />
        </div>
        <span>support@karizma.co</span>
      </div>

      <div className="right-footer">
        <div className="languages">
          <span
            onClick={() => changeLanguage('en')}
            className={language === 'en' ? 'active' : 'inactive'}
          >
            EN
          </span>
          {region === 'ar' ? (
            <span
              onClick={() => {
                changeLanguage('ar')
              }}
              className={region === 'ar' && language === 'ar' ? 'active' : 'inactive'}
            >
              AR
            </span>
          ) : null}
        </div>
        <div onClick={onChangeRegion} className="change-region">
          <Worldwide color={userType === UserType.candidates ? 'white' : '#54585F'} />
          <span>{region === 'ar' && language === 'ar' ? 'تغيير المنطقة' : 'Change region'}</span>
          <ArrowDown color={userType === UserType.candidates ? 'white' : '#54585F'} />
        </div>
      </div>
    </div>
  )
}

export default Footer
