import React, { useEffect, ReactNode } from 'react'

import { Close } from 'icons'

interface ModalProps {
  visible: boolean,
  onHide: () => void,
  children: ReactNode,
}

function Modal({ visible, onHide, children }: ModalProps): ReturnType<React.FC> {
  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (visible && e.target instanceof HTMLElement) {
        const modalContent = document.querySelector('.modal-content')
        if (modalContent && !modalContent.contains(e.target)) {
          onHide()
        }
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [visible, onHide])

  return (
    <div className={`modal-container ${visible ? 'active' : ''}`}>
      <div className="modal-background">
        <div className="modal-content">
          <div className="close-icon" onClick={onHide}>
            <Close />
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
