/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'

import CONFIG from '../config.json'
import { UserType } from '../App'

interface StoreButtonsProps {
  userType: keyof typeof UserType,
}

function StoreButtons({ userType }: StoreButtonsProps) {
  return (
    <div className="store-images">
      <img
        onClick={
          userType === UserType.employers
            ? () => window.open(CONFIG.employerAndroidUrl)
            : () => window.open(CONFIG.candidateAndroidUrl)
        }
        alt="google-play"
        src="./images/googlePlay.webp"
      />
      <img
        onClick={
          userType === UserType.employers
            ? () => window.open(CONFIG.employerAppleUrl)
            : () => window.open(CONFIG.candidateAppleUrl)
        }
        alt="app-store"
        src="./images/appStore.webp"
      />
    </div>
  )
}

export default StoreButtons
