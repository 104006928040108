import React from 'react'

interface ButtonProps {
  label: string,
  onClick: () => void,
  background: string,
}

function Button({ label, onClick, background }: ButtonProps) {
  return (
    <button
      className={`button ${background}`}
      style={{ background }}
      type="button"
      onClick={onClick}
    >
      {label}
    </button>
  )
}

export default Button
