/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import * as emailjs from '@emailjs/browser'

import CONFIG from '../../config.json'

interface ContactUsBlockProps {
  language?: string,
}

function ContactUsBlock({ language }: ContactUsBlockProps): ReturnType<React.FC> {
  const [email, setEmail] = useState('')
  const [isEmailSending, setIsEmailSending] = useState(false)
  const [isWrongEmail, setIsWrongEmail] = useState(false)
  const [isEmailSent, setIsEmailSent] = useState(false)

  useEffect(() => {
    if (email.length === 0) {
      setIsWrongEmail(false)
    }
  }, [email])

  const onSendEmail = async () => {
    if (!email) return
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    const isValidEmail = emailPattern.test(email)
    setIsEmailSending(true)

    if (!isValidEmail) {
      setIsWrongEmail(true)
      setIsEmailSending(false)
      return
    }

    setIsWrongEmail(false)

    try {
      await emailjs.send(
        CONFIG.serviceId,
        CONFIG.emailTemplateId,
        { email },
      )
      setIsEmailSent(true)
    } catch (e) {
      console.log(e)
      alert('Cannot send email')
    } finally {
      setEmail('')
      setIsEmailSending(false)
      setTimeout(() => { setIsEmailSent(false) }, 2000)
    }
  }

  return (
    <div className="contact-us-block">
      <p>{language === 'ar' ? 'اتصل بنا' : 'Contact Us'}</p>
      <span>
        {language === 'ar' ? (
          <span>
            إذا كان لديك أي أسئلة، يرجى ترك عنوان بريدك الإلكتروني وسيقوم فريق الدعم لدينا بالاتصال بك.
          </span>
        ) : (
          <span>
            If you have any questions, please leave your email address
            <br />
            and our support team will contact you.
          </span>
        )}
      </span>
      <div className="send-email">
        <div className="input-container">
          <input
            value={email}
            type="email"
            placeholder={language === 'ar' ? 'عنوان البريد الإلكتروني' : 'Email address'}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        {isWrongEmail ? <span className="error-message">Wrong email format, try another one</span> : null}
        {isEmailSent ? <span className="success-message">Sent successfully!</span> : null}
        <button
          className={`${isEmailSending ? 'loading' : ''} ${isWrongEmail || isEmailSent ? 'with-message' : ''}`}
          disabled={isEmailSending}
          type="button"
          onClick={onSendEmail}
        >
          {isEmailSending ? <img width="24" src="./images/loading.gif" alt="Loading" /> : (
            language === 'ar' ? 'يرسل' : 'Send'
          )}
        </button>
      </div>
    </div>
  )
}

export default ContactUsBlock
